<template>
    <!-- <div id="sob-video-library"></div> -->
    <section>
      <!-- <pre>{{ tutorialVideos }}</pre> -->
      <div class="bg-white p-14 rounded-lg" >
        <h3 style="font-size: 24px;"> <strong>Quizforma</strong> Video Training Center </h3>
        <h6 class="mt-8 mb-8" style="font-size: 16px; font-weight: 400;">
          In this training center, you will be able to view each and every feature
          inside this platform
        </h6>
  
        <div class="row mt-4">
          <div class="col-md-4 mb-md-0 mb-2" style="position: relative;">
            <select
              class="form-control"
              id="tutorialLibraryCategorySwitcher"
              v-model="video.category_id"
              @change="fetchTutorialVideos"
            >
              <option value="">All</option>
              <option
                :value="category.id"
                v-for="(category, index) in videoCategories"
                :key="index"
              >
                {{ category.name }}
              </option>
            </select>
            <div class="dropdown-icon">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-3" viewBox="0 0 320 512"><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>
            </div>
          </div>
          <div class="col-md-4 offset-md-4 text-right">
            <div class="form-control-wrapper form-control-icon-right" style="position: relative;">
              <input
                type="search"
                class="form-control form-control-rounded"
                style="border-radius: 25rem;"
                placeholder="Search Video"
                v-model="video.keyword"
                @input="fetchTutorialVideosDebounce"
              />
              <div class="dropdown-serach">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6" viewBox="0 0 512 512">
                  <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
  
        <div class="row gap-5 mb-6" v-for="(category,ind) in videoCategories.filter(c => c.tutorialvideos_count > 0 && filteredCategoriesIds.includes(c.id)).filter((cat) => video.category_id ? cat.id == video.category_id : true)" :key="ind">
          <h5 class="mt-10 mb-0" style="font-size: 1.55rem; font-weight: 400;">{{ category.name }}</h5>
          <div
            class="col-md-4 mt-5"
            v-for="(video, index) in tutorialVideos.filter(v => v.tutorial_video_category_id == category.id)"
            :key="index"
          >
            <div class="h-100 video-card card">
                <div class="card-body" >
                    <h4 class="card-title" >{{ video.name }}</h4>
                    <p class="video-description card-text" >{{ video.description }}</p>
  
                    <div class="embed-container">
                        <iframe
                        v-if="video.type == 1"
                        :src="`//www.youtube.com/embed/${video.video_permalink}?rel=0`"
                        frameborder="0"
                        webkitAllowFullScreen
                        mozallowfullscreen
                        allowFullScreen
                        ></iframe>
                        <iframe
                        v-else-if="video.type == 2"
                        :src="`//player.vimeo.com/video/${video.video_permalink}`"
                        frameborder="0"
                        webkitAllowFullScreen
                        mozallowfullscreen
                        allowFullScreen
                        ></iframe>
                        <iframe
                        v-else-if="video.type == 3"
                        :src="`//videogeyser.com/player/${video.video_permalink}/?VideoControls=true&AutoPlayVideo=false&FullScreenAllowed=true`"
                        allowtransparency="true"
                        frameborder="0"
                        scrolling="auto"
                        allowfullscreen
                        mozallowfullscreen
                        webkitallowfullscreen
                        oallowfullscreen
                        msallowfullscreen
                        ></iframe>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
    </section>
  </template>
  
  <script>
  import _ from "lodash";
  import { mapGetters, mapActions } from "vuex";
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  export default {
    name: "VideoLibrary",
    // mounted() {
    //   VideoLibrary.init([
    //     "sob-video-library",
    //     "https://app.saasonboard.com/",
    //     "ZFNyUiQxiokwwpN",
    //     "video-library-iframe"
    //   ]);
    // }
    components: {
    },
    data() {
      return {
        videoCategory: "",
        videoSearch: null,
        video: {
          company_id: 73,
          category_id: "",
          keyword: "",
        },
        filteredCategoriesIds : []
        // videoCategories: [
        //   {
        //     id: 1,
        //     name: "Hello",
        //   },
        // ],
      };
    },
    computed: {
      ...mapGetters({
        videoCategories: "SOB/getVideoCategories",
        tutorialVideos: "SOB/getTutorialVideos",
      }),
    },
    mounted() {
      this.setVideoCategories();
      this.fetchTutorialVideos();
      setCurrentPageTitle("Video Tutorials");
    },
    methods: {
      ...mapActions({
        setVideoCategories: "SOB/setVideoCategories",
        setTutorialVideos: "SOB/setTutorialVideos",
      }),
      fetchTutorialVideos() {
        this.setTutorialVideos(this.video).then(
          (res) => {
            if (res.data.data) {
              this.filteredCategoriesIds = res.data.data.map(({tutorial_video_category_id}) => tutorial_video_category_id)
            }
          }
        );
      },
      fetchTutorialVideosDebounce: _.debounce(function () {
        this.fetchTutorialVideos();
      }, 500),
    },
  };
  </script>
  
  <style scoped>
  .embed-container {
    margin-bottom: 1.1em;
    margin-top: 1.5em;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 96%;
    height: 100%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .card-title {
    color: #343434;
    font-size: 23px;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 700;
  }
  .video-description {
    color: #343434;
    min-height: 42px;
    margin-top: 18px;
    font-family: Poppins, sans-serif;
    font-size: 12px;
  }
  .dropdown-icon {
    position: absolute;
    top: 50%;
    right: 3%;
    transform: translate(-50% , -50%);
  }

  .dropdown-icon svg {
    fill: #555;
  }
  .dropdown-serach {
    position: absolute;
    top: 50%;
    right: 1%;
    transform: translate(-50% , -50%);
  }

  .dropdown-serach svg {
    fill: #888;
  }
  
  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    .card-title {
      font-size: 20px;
      font-family: Poppins, sans-serif;
    }
    .video-description {
      margin-top: 13px;
      font-family: Poppins, sans-serif;
    }
  }
  
  .video-card{
    background-color: #f5f6f9;
    border: 1px solid #d8e2e7;
  }
  </style>